import TextComponent from "./reusable/TextComponent.jsx"
// import NumberComponent from "./old-components/NumberComponent.jsx"
import SelectComponent from "./old-components/SelectComponent.jsx"
import LabelComponent from "./reusable/LabelComponent.jsx"
import SwitchComponent from "./reusable/SwitchComponent.jsx"
import SliderComponent from "./slider/SliderComponent.jsx"
import OptionalComponent from "./specific/OptionalComponent.jsx"
import { useSelector } from 'react-redux';
import {
    get
} from '../../reducers/data';
import SelectAtViewComponent from "./selects/SelectAtView.jsx"
import MultiSelectAtViewComponent from "./selects/MultiSelectAtViewComponent.jsx"
import PercentageComponent from "./specific/PercentageComponent.jsx"
import RisparmioPreliminareComponent from "./reusable/RisparmioPreliminareComponent.jsx"
import SelectAtViewFotoComponent from "./selects/SelectAtViewFotoComponent.jsx"
import utils from "../../utils/index.js"
import RiepilogoComponent from "./riepilogo/RiepilogoComponent"
import SelectButtonComponent from "./dropdown/SelectButtonComponent.jsx"
import StepperComponent from "./reusable/StepperComponent.jsx"
import AddressCAPCityComponent from "./specific/AddressCAPCityComponent.jsx"
import OptionalClimaComponent from "./old-components/OptionalClimaComponent.jsx"
import ConfiguraRiscaldamentoComponent from "./specific/ConfiguraRiscaldamentoComponent.jsx"
import BoxComponent from "./reusable/BoxComponent.jsx"
import SelectAtViewBoxComponent from "./selects/SelectAtViewBox.jsx"
import { DsyDivider } from "../../dsy-wrappers/dsy-divider.js"
import FacSimileCopyComponent from "./fac-simile/FacSimileCopy.jsx"
import BoxFotoVirtualeComponent from "./specific/BoxFotoVirtualeComponent.jsx"
import GasDisclaimerComponent from "./specific/GasDisclaimerComponent.jsx"
import ProductBox from "./reusable/ProductBoxComponent.jsx"
import ProductSelectComponent from "./reusable/ProductSelectComponent.jsx"

const testRendering = utils.testRendering

function FormPage({ items, riepilogo = true }) {
    const data = useSelector(get);

    const mapComponent = (item, ) => {
        if (item.conditionalRendering && testRendering(item, data) === false) return null;

        const k = item.cell ?? item.name

        switch(item.type){
            case "spacer": return <div className={"spacer mb-32 " + item.extraClass } key={k}></div>
            case "divider": return <div className="my-4" aria-hidden="true" key={k}><div className={"divider-parent " + (item.extraClass ??  "")}> <DsyDivider  /></div></div>
            case "facsimile": return <FacSimileCopyComponent {...item} key={k} />
            case "small-note": return process.env.REACT_APP_RATE === "true" ? <p key={k} className="small-note f12" dangerouslySetInnerHTML={{ __html: item.text}}></p> : <></>
            case "gasdisclaimer": return <GasDisclaimerComponent {...item} key={k} />
            case "choiceatviewfoto":
                return <SelectAtViewFotoComponent {...item} key={k} />
            case "html": return item.text
            case "box": 
                return <ProductBox {...item} key={k} />
                //return <BoxComponent {...item} key={k} />
            case "summary": 
                return <RisparmioPreliminareComponent {...item} key={k} />
            case "address":
                return <AddressCAPCityComponent {...item} key={k} />
            case "choiceatviewbox": 
                return <ProductSelectComponent {...item}  />
                // return <SelectAtViewBoxComponent {...item} key={k} />
            case "multichoice": 
                return <MultiSelectAtViewComponent {...item} key={k} />
            case "percentage": 
                return <PercentageComponent {...item} key={k} />
            case "choiceatview":
                return <SelectAtViewComponent {...item} key={k} />
            case "optional":
                return <OptionalComponent {...item} key={k} />
            // case "optionalclima":
            //     return <OptionalClimaComponent {...item} key={k} />
            case "slider": 
                return <SliderComponent {...item} key={k} />
            case "stepper":
                return <StepperComponent {...item} key={k} />
            case "bool":
                return <div className={item.extraClass ?? "" } key={k}><SwitchComponent {...item} key={k} /></div>
            case "label":
                return <LabelComponent {...item} key={k} />
            case "boxfotovirtuale": 
                return <BoxFotoVirtualeComponent />
            case "text":
                return <div className={"mb-16 " + item.extraClass ?? "" } key={k}><TextComponent {...item} /></div>
            case "riscaldamento"
                : return <div className={"mb-16 " + item.extraClass ?? ""} key={k}><ConfiguraRiscaldamentoComponent {...item} /></div>
            // case "number":
            // return <div className={"mb-16 " + item.extraClass ?? ""} key={k}><NumberComponent {...item} /></div>
            case "choice":
                return <div className={"mb-16 " + item.extraClass ?? ""} key={k}><SelectComponent {...item} /></div>
            case "choicebuttons":
                return <div className={"mb-16 " + item.extraClass ?? ""} key={k}><SelectButtonComponent {...item} /></div>
            default: return <div>TODO: {item.type}</div>
        }
    }
    // col-lg-7 px-0"
    return (<div className="form-page-component row" >
        {items.map((x, i) => mapComponent({...x, index: i}))}

        {riepilogo !== false && <RiepilogoComponent />}
    </div>);
}

export default FormPage;