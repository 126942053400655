import React, {useState} from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import ContactsModalComponent from './ContactsModalComponent';
// import { DsyButton } from '../../../dsy-wrappers';
import { GriButton } from '../../dsy-inhouse/GriButtonComponent';
import { useSelector, useDispatch } from 'react-redux'
import { set, get } from '../../../reducers/data'

function BookAppointment ({changeModal}) {
      const data = useSelector(get)
    if (data && data["negozi"]) 
        return <> <Col lg={12} className="desktop-cta gx-5">
                <GriButton 
                iconPosition='right'
                icon="document-text"
                library={"heroicons"}
                variant="secondary" shape="square" className="me-2" onClick={() => {
                 window.location.href = data["ProjectUrl"]
                }
                    } >Stampa report finale</GriButton>
                <GriButton shape="square" 
                iconPosition='right'
                icon="email"
                onClick={() => changeModal(true)}>Invia report finale al cliente</GriButton>
              </Col>
            </>

    return <>
    {/* <Container fluid className="book-appointment">
        <Container>
        <Row> 
            <Col>
                <h2>Prenota un appuntamento con un nostro operatore per finalizzare la tua soluzione</h2>
                <p>Scegli una data e una fascia oraria in cui intendi essere ricontattato</p>
            </Col>
            <Col className="justify-content-end d-flex align-items-center">
                <button onClick={() => changeModal(true)}>PRENOTA UN APPUNTAMENTO</button>
            </Col>
        </Row>

       
        </Container>

       
    </Container> */}
    <Col lg={12} className="desktop-cta">
            {/* <Row>
                <Col className="justify-content-center d-flex align-items-center"> */}
                    <GriButton shape="square" onClick={() => changeModal(true)}>Ricevi il report e prenota una consulenza</GriButton>
                {/* </Col>
            </Row> */}
            {/* <Row>
                <Col className="text-center">
                Scegli la fascia oraria in cui intendi essere ricontattato
                </Col>
            </Row> */}
        </Col>
    </>
}

export default BookAppointment;