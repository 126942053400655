import React, { useState } from 'react'
import { Container, Row, Col, ButtonGroup, Button } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { set, get } from '../../../reducers/data'
import { MigrationContentSwitcher } from '../../../dsy-wrappers/dsy-migration-content-switcher'

import Clima from "../../../icons/icon/clima.png"
import Bespoke from "../../../icons/icon/new/Frigo_Bespoke.png"
import Frigo75 from "../../../icons/icon/new/Frigo_75.png"
import TV from "../../../icons/icon/new/TV_Pic.png"
import Asciugatrice from "../../../icons/icon/new/Asciugatrice.png"
import Lavatrice from "../../../icons/icon/new/Lavatrice.png"
import Fotovoltaico from "../../../icons/icon/new/Foto.png"
import FotoBatteria from "../../../icons/icon/new/final_foto_batteria.png"
import Induzione from "../../../icons/icon/new/Induzione.png"
import RisparmioBox from '../reusable/RisparmioBoxComponent'
import FotoVirtuale from "../../../icons/icon/new/FotoVoltaicoVirtuale.png"
import FotoPlug from "../../../icons/icon/new/FotoMuratura.png"


const prodottiOnline ={
  "Frigorifero Combinato Samsung F1rst 75 AI": 
  {
    id:"RB53DG706AB1EF",
    rate: "24 rate mensili da 82,99€",
    prezzo: "1.991,76€*",
    tan:  "TAN 7.50% - TAEG 7.72%"
  },
  "Frigorifero Combinato Samsung BESPOKE AI": {
    id: "RB38C7AGWS9",
    rate: "24 rate mensili da 78,99€",
    prezzo: "1.895,76€*",
    tan:  "TAN 7.50% - TAEG 7.71%"
  },
  "Samsung Crystal UHD 43\"": {
   id: "UE43DU8075UXZT",
   rate: "24 rate mensili da 22,99€",
   prezzo: "551,76€*",
    tan:  "TAN 7.50% - TAEG 7.71%"
  },
  "Samsung Crystal UHD 55\"": {
    id: "UE55DU8075UXZT",
    rate: "24 rate mensili da 29,99€",
    prezzo: "719,76€*",
    tan:  "TAN 7.50% - TAEG 7.71%"
  },
  "Samsung Crystal UHD 65\"": {
    id: "UE65DU8075UXZT",
    rate: "24 rate mensili da 35,99€",
    prezzo: "863,76€*",
    tan:  "TAN 7.50% - TAEG 7.70%"
  },
  "Asciugatrice Samsung BESPOKE AI - Silent Dry":{
    id: "DV90BB7445GES3",
    rate: "24 rate mensili da 41,99€",
    prezzo: "1.007,76€*",
    tan:  "TAN 7.50% - TAEG 7.72%"
  },
  "Lavatrice Samsung BESPOKE AI - EcoDosatore": {
    id:"WW11DB7B94GEU3",
    rate: "24 rate mensili da 39,99€",
    prezzo: "959,76€*",
    tan:  "TAN 7.50% - TAEG 7.73%"
  },
  "Samsung Piano a Induzione Slim Fit 60cm": {
    id:"NZ64B7799KK",
    rate:"24 rate mensil da 58,99€",
    prezzo: "1.415,76€*" ,
    tan:  "TAN 7.50% - TAEG 7.71%"
  }
}

const prodottiNegozi = {
  "Frigorifero Combinato Samsung F1rst 75 AI": 
  {
    id:"RB53DG706AB1EF",
    rate: "24 rate mensili da 74,99€",
    prezzo: "1.799,76€*",
    tan:  "TAN 0% - TAEG 0%"
  },
  "Frigorifero Combinato Samsung BESPOKE AI": {
    id: "RB38C7AGWS9",
    rate: "24 rate mensili da 70,99€",
    prezzo: "1.703,76€*",
    tan:  "TAN 0% - TAEG 0%"
  },
  "Samsung Crystal UHD 43\"": {
   id: "UE43DU8075UXZT",
   rate: "24 rate mensili da 19,99€",
   prezzo: "479,76€*",
    tan:  "TAN 0% - TAEG 0%"
  },
  "Samsung Crystal UHD 55\"": {
    id: "UE55DU8075UXZT",
    rate: "24 rate mensili da 25,99€",
    prezzo: "623,76€*",
    tan:  "TAN 0% - TAEG 0%"
  },
  "Samsung Crystal UHD 65\"": {
    id: "UE65DU8075UXZT",
    rate: "24 rate mensili da 30,99€",
    prezzo: "743,76€*",
    tan:  "TAN 0% - TAEG 0%"
  },
  "Asciugatrice Samsung BESPOKE AI - Silent Dry":{
    id: "DV90BB7445GES3",
    rate: "24 rate mensili da 36,99€",
    prezzo: "887,76€*",
    tan:  "TAN 0% - TAEG 0%"
  },
  "Lavatrice Samsung BESPOKE AI - EcoDosatore": {
    id:"WW11DB7B94GEU3",
    rate: "24 rate mensili da 35,99€",
    prezzo: "863,76€*",
    tan:  "TAN 0% - TAEG 0%"
  },
  "Samsung Piano a Induzione Slim Fit 60cm": {
    id:"NZ64B7799KK",
    rate:"24 rate mensil da 50,99€",
    prezzo: "1.223,76€*" ,
    tan:  "TAN 0% - TAEG 0%"
  }
}

function Prodotto ({ item, key, index, selected, extraMessage }) {
  const data = useSelector(get)
  if (!item.modello || !item.scontato){
    console.log(item)
    return <div key={key}></div>
  }

  const isElettrodomestico = item.name === "Televisore" || item.name === "TV" || item.name === "Asciugatrice" || item.name === "Frigo" || item.name === "Lavatrice" || item.name === "Induzione"
  const images = {
    "Frigorifero Combinato Samsung BESPOKE AI": Bespoke,
    "Frigorifero Combinato Samsung F1rst 75 AI": Frigo75,
  }

  let rate = null
  let scontato = null
  let codice =  item.modello.indexOf("Plug&Play") > -1 ? "Consegna bordo strada inclusa" :"Installazione inclusa"
  let tan = null

  const prodotti = data["negozi"] ? prodottiNegozi : prodottiOnline
  
  if(isElettrodomestico && prodotti[item.modello] === undefined) {
    console.log(item)
    return <div key={key}></div>
  }

  if (isElettrodomestico) {
    codice = prodotti[item.modello].id
    rate = prodotti[item.modello].rate
    scontato = prodotti[item.modello].prezzo
    tan = prodotti[item.modello].tan
  }

  let img = images[item.modello]
  if (item.name === "Clima") img = Clima
  if (item.name === "Fotovoltaico") img = Fotovoltaico
  if (item.modello.includes("Batteria") || item.name.includes("Batteria")) img = FotoBatteria
  if (item.modello.includes("Plug")) img = FotoPlug
  if (item.name === "TV") img = TV
  if (item.name === "Asciugatrice") img = Asciugatrice
  if (item.name === "Lavatrice") img = Lavatrice
  if (item.name === "Induzione") img = Induzione

  return (
    <Col md={12} key={key}>
      <div className='product'>
          <div className='top d-flex mb-2 mb-lg-0 justify-content-between col-12 col-lg-6'>
            <div className="img-container">
                <img src={img} alt="" role="presentation" />
            </div>
            <div className="product-container" style={{ flexGrow: 1 }}>
              <span className='tag'>{item.name}</span>
              <div className='product-title'>
                {item.link && item.link.length > 1 ? 
                <a target='_blank' rel='noreferrer'>{item.modello} </a>
                 : 
                 <>{item.modello}</>
                 }
              </div>
              <div className='d-flex justify-content-between light'>
                 {codice && <div className='codice'>{codice}</div>}
                <div className='qty'>Quantità: {item.qty}</div>
              </div>
            </div>
          </div>
          <div className={ "col-12 col-lg-6 d-flex bottom flex-column " + (extraMessage ? "justify-content-between" : "justify-content-center") }>
          <div className=' d-flex justify-content-between  align-items-center'>
            <div className='light'>
              <b>Totale</b> <br />
              (IVA inclusa)
            </div>
            <div className='price'>
              <div className='final'>{scontato ?? item.scontato+ ",00€"}</div>
              <div className='sub'>
                {rate && <p className='f14 mb-0' style={{  color: "#272C34"}}>{rate}</p>}
                {tan && <p className='f14 mb-0 bold' style={{  color: "#272C34"}}>{tan}</p>}
                {tan && <p className="f12">Prodotto disponibile solo con <br /> finanziamento tramite finanziaria</p>}
                {/* <span className='green'>Ecobonus {item.ecobonus}&nbsp;</span> */}
                {/* <span className='striked'>{item.costo.replace(",", ".")}€</span> */}
              </div>
            </div>
            </div>
            { extraMessage && <div className='extra-message'>{extraMessage}</div> }
          </div>
          
      </div>
    </Col>
  )
}

const txts = {
  "Clima":"Per migliorare il riscaldamento della tua casa ti suggeriamo di usare un climatizzatore a pompa di calore:",
  "ScaldAcqua":"Per migliorare la produzione di acqua calda della tua casa ti suggeriamo di usare uno scalda acqua a pompa di calore:",
  "Induzione":"Per migliorare la cottura cibi della tua casa ti suggeriamo di usare una piastra ad induzione:",
  "Fotovoltaico":"Per migliorare l'efficienza energetica della tua casa ti suggeriamo di usare un sistema di autoproduzione:"
}

function FinalHeaderComponent () {
  const data = useSelector(get)
  const prodotti = data.products.reduce((acc, item) => {
    if (item.name === "TV" || item.name==="Asciugatrice" || item.name === "Frigo" || item.name === "Lavatrice") {
      if (acc["Elettrodomestici"]){
        acc["Elettrodomestici"].push(item)
      }
      else {
        acc["Elettrodomestici"] = [item]
      }
    }
    else {
    if (acc[item.name]) {
      acc[item.name].push(item)
    } else {
      acc[item.name] = [item]
    }
  }
    return acc
  }, {})
  let startingCategory
  Object.keys(prodotti).forEach(key => {
    if (prodotti[key].length > 0) {
      if(startingCategory === undefined)
        startingCategory = key
      return
    }
  })
  if (data["ConsumoOrario"] === true) prodotti["Offerta luce"] = null
  const [selected, setSelected] = useState(startingCategory) 
  const copy = txts[selected]
  const t = {
    "Clima": "Riscaldamento",
    "ScaldAcqua": "Acqua Calda",
  }
  
  return (
    <div className='header'>
      <Container fluid className='final-header mx-0'>
        <Row>
          <Col md={12} className='right-side'>
          <ButtonGroup className='buttons gri-buttons'  role="tablist">
              {Object.keys(prodotti).map((key, index) => (
                <Button
                  role="tab"
                  aria-selected={key === selected}
                  key={index}
                  variant='outline-primary'
                  className={key === selected ? 'active' : ''}
                  onClick={() => setSelected(key)}
                >
                  {key.replace("Acqua", "acqua").replace("Clima", "Riscaldamento").replace("Induzione", "Cottura Cibi")}
                </Button>
              ))}
            </ButtonGroup>
            {/* <MigrationContentSwitcher 
              entries={Object.keys(prodotti).map(key => ({key, label: t[key] ?? key}))} 
              onClick={({ key }) => setSelected(key)}
              preselectedContentKey={selected} /> */}
            {/* {copy && <div id="final-message" className='copy'>{copy}</div>} */}
            <Row className='prodotti'>
              {selected === "Offerta luce" && <Col> <RisparmioBox 
                title="Solare Virtuale"
                description="da 1.0kW"
                tag="Offerta luce"
                image={FotoVirtuale}
                attualeTitle={"Costo iniziale"}
                attualeDescription={"IVA inclusa"}
                attualeValue={"99,00€"}
                futuraTitle={"Abbonamento mensile"}
                futuraDescription={"IVA inclusa"}
                futuraValue={"12€ + CCV 10€/mese"}
             /></Col>}
              {prodotti[selected]?.map((item, index) => (
                <Prodotto
                  key={index}
                  item={item}
                  index={index}
                  selected={selected}
                  // extraMessage={"Paga il tuo acquisto in 10 comode rate mensili da 39€"}
                />
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default FinalHeaderComponent
