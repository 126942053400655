
import { useDispatch, useSelector } from 'react-redux';
import {
    set, get
} from '../../../reducers/data';
import React from 'react';
import top from "../../../icons/top.svg"
import bottom from "../../../icons/bottom.svg"
import center from "../../../icons/center.svg"
import fotovoltaico from "../../../icons/icon/fotovoltaico.svg"
import batteria from "../../../icons/icon/batteria.svg"
import day from "../../../icons/icon/new/day.svg"
import night from "../../../icons/icon/new/night.svg"

function SelectAtViewComponent({ name, cell, description, choices, horizontal, extraClass, component }) {
    const dispatch = useDispatch();
    const data = useSelector(get);
    const currentValue = data[cell]
    const icons = [bottom, center, top, fotovoltaico, batteria, day, night]

    const titleCN = "title " + (description ? "mb-0" : "") 
  
    return (
        <div className={"selectatview-component mb-16 " + (data ? "filled " : "") + (extraClass ?? "")}>
            {component ? <h2 className={titleCN}>{name} </h2> : <div className={titleCN}>{name} </div>}
            {description && <p className="description">{description}</p>}

            {choices && <fieldset className={"choice-box no-border " + (horizontal ?? "")} role="radiogroup">
                <legend className="visually-hidden">{name}</legend>
                {choices.map((x, i) =>
                    <label key={i}
                        className={"w-100 no-bold  no-bg text-left single-box d-flex align-items-center" 
                        + (currentValue === x.value ? " selected": "")
                        + (i === 0 ? " first" : "")
                        + (i === choices.length -1 ? " last" : "")} >
                        <input aria-label={x.text} 
                            type="radio" 
                            checked={data[cell] === x.text} 
                            aria-checked={data[cell] === x.text}
                            className="choice" 
                            name={name} 
                            value={x.text}
                            id={x.text}
                            onChange={(e)  => {
                                dispatch(set({
                                    cell: cell,
                                    value: x.value
                                }))

                                if (cell === "FornituraEnel"){
                                    dispatch(set({
                                        cell: "FotoVirtuale",
                                        value: !x.value
                                    }))
                                }
                            }}
                           ></input>
                        { x.image !== undefined &&<img aria-hidden={true} src={icons[x.image]} className="icon-select" alt="" role='presentation' /> }
                        <span aria-hidden={true} className="flex-grow-1 text-start" htmlFor={x.text}>{x.text}</span>
                    </label>
                )
                }
            </fieldset>}
             {/* <div id="liveRegion" 
                aria-live="polite" 
                className="visually-hidden">{data[cell] + " attivato"}</div> */}
        </div>
    );
}

export default SelectAtViewComponent;